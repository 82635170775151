body {
  font-family: 'Roboto', sans-serif;
}

.text-bold {
  font-weight: bold;
}

.btn {
  border-radius: 25px;
  color: $white !important;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1.5rem;
}

.btn-white {
  background: #FFF;
  color: $blue !important;
}

.btn-primary {
  &:hover {
    background-color: $green;
    border-color: $green;
  }
}

.btn-outline-primary {
  color: $blue !important;

  &:hover {
    background: $green;
    color: $white !important;
    border-color: $green;
  }
}

.drop-header-btn {
  display: inline-flex;
  height: 50px;
  padding: 10px 20px 0;
  background-color: #fff;
  color: #0e204d;
  border-radius: 8px 8px 0 0;
  font-size: 13px;
  cursor: pointer;
  position: relative;
  font-weight: 400;

  &::after {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    font-size: 11px;
    position: absolute;
    border: none;
    bottom: 7px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1;
  }

}

.drop-header {
  width: 260px;
  padding: 30px 30px 22px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 5px 83px 0 rgba(14, 32, 77, 0.15);
  border: none;

  .drop-header-mute {
    border-bottom: 2px solid #e6e8eb;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.btn-link {
  color: $blue !important;
  transition: all 500ms ease;
  font-weight: bold;
  text-decoration: none;

  &::after {
    content: "\f061";
    font-family: "Font Awesome 5 Pro";
    margin-left: 10px;
    color: $cyan;
  }

  &:hover {
    color: $green;
    text-decoration: none;

  }
}

.header-info-col {
  font-size: 13px;
  color: #f4f4f4;
  line-height: 60px;
}

.navbar-brand {
  img {
    height: 65px;
  }
}
@media (max-width: 576px) {
  .navbar-brand {
    img {
      height: 45px;
    }
  }
}

.navbar {
  background: $white;
  transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  padding: 0;
  z-index: 99;

}

.navbar-nav {
  li.nav-item {
    font-size: 15px;
    letter-spacing: 0.02em;
    color: $blue;
    font-weight: 700;
    line-height: 100px;
    position: relative;
    padding: 0 16px;

    a.nav-link {
      border-top: 4px solid $white;
      transition: all 0.3s ease;

      &:hover {
        border-top: 4px solid $green;

      }
    }
  }
}


.nav-link {
  color: $blue;

  .dropdown-toggle {

    &::after {
      content: "\f061";
      font-family: "Font Awesome 5 Pro";

    }
  }
}

.nav-item {
  color: $blue;

  a {
    color: $blue;
    font-weight: 500;
    font-size: 16px;

    &:hover {
      color: $green;
    }
  }
}

.dropdown-menu {
  border-radius: 0 0 10px 10px;
  box-shadow: 0 5px 83px 0 rgba(14, 32, 77, 0.15);
  border: none;

  .dropdown-item {
    line-height: 36px;
  }

}

.navbar.scrolled {
  position: fixed;
  width: 100%;
  top: 0;

  .navbar-nav li.nav-item {
    line-height: 50px;
  }
}

.carousel_home {
  height: 650px;

  h5 {
    font-family: 'Quicksand', sans-serif;
    color: $blue;
    font-weight: 700;
    font-size: 75px;
    line-height: 80px;
    text-shadow: 2px 2px 2px #FFFFFF;
  }

  .carousel-caption {
    bottom: inherit;
    top: 150px;
  }

  .carousel-item {
    height: 650px;
  }

  .carousel-indicators {
    li {
      background-color: $green;
    }
  }
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 50px;
  height: 50px;
  font-size: 50px;
  background: none !important;
}

.carousel-control-next-icon:before {
  background-image: none !important;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  content: "\f054";
  color: $green;
}

.carousel-control-prev-icon:before {
  background-image: none !important;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  content: "\f053";
  color: $green;
}

.image-slide {
  height: 650px;
  background-position: center;
  background-size: cover;
}

.bg_curves {
  background-color: #FFFFFF;
  background-image: url("/front/images/design/service_curves.png");
  background-position: top center;
  background-repeat: no-repeat;
}

.bg_curves_in {
  background-color: transparent;
  background-image: linear-gradient(180deg, #21CDC01A 0%, #FFFFFF 100%);
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.h2 {
  font-family: 'Quicksand', sans-serif;
  color: $blue;
  font-weight: 700;
  font-size: 37px;
  line-height: 1.46;
}

.text-grey {
  color: rgb(132, 142, 159);
  line-height: 27px;
  font-weight: 400;
}

.laius_blue {
  line-height: 27px;
  font-size: 16px;
  font-weight: 500;
  color: $blue;
}

.bloc_radius {
  z-index: 50;
  position: relative;
  border-radius: 25px;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+30,ffffff+100&0+0,1+30,1+100 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */

}

.imgfloater {
  z-index: 30;
  bottom: -190px;
  position: absolute;
  border-radius: 25px;
  overflow: hidden;
  background-image: url("/front/images/design/home05.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 630px;
  height: 380px;
}

.bloc_item {
  background: #FFF;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px 0 rgba(14, 32, 77, 0.15);

  h5 {
    font-family: 'Quicksand', sans-serif;
    color: $blue;
    font-weight: 700;

  }
}

.bloc_team {
  background: #FFF;
  box-shadow: 0px 5px 15px 0 rgba(14, 32, 77, 0.15);
  padding: 1px 1px 30px 1px;
  border-radius: 25px;
  overflow: hidden;

  .img_produit {
    border-radius: 25px 25px 0 0;
    width: 100%;
    height: 250px;
    overflow: hidden;

    img {
      filter: grayscale(1);
      transition: all 1000ms ease;

    }
  }

  .img_membre {
    border-radius: 25px 25px 0 0;
    width: 100%;
    overflow: hidden;


    img {
      filter: grayscale(1);
      transition: all 1000ms ease;

    }
  }

  h5 {
    font-family: 'Quicksand', sans-serif;
    color: $blue;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  &:hover {
    .img_membre, .img_produit {
      img {
        filter: grayscale(0);
        transform: scale(1.2);
      }

    }
  }


}

.footer {
  img {
    max-height: 50px;
    margin-bottom: 30px;
  }
}

.footer-bot {
  color: rgb(132, 142, 159);
  font-size: 13px;
  font-weight: 300;
}


.container_title {
  background: $blue;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  background-image: url("/front/images/design/home01.jpg");
  background-size: cover;

  .bg_filter {
    opacity: 0.8;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
  }

  .title_page {
    font-family: 'Quicksand', sans-serif;
    color: $white;
    font-weight: 700;
    padding-bottom: 200px;
    padding-top: 150px;
    text-align: center;
    width: 100%;
    position: relative;
    z-index: 20;

  }

}

.container-radius {

  background: $white;
  border-radius: 25px;
  padding: 40px;
  position: relative;
  top: -80px;
  min-height: 200px;
  z-index: 30;

  h3 {
    font-family: 'Quicksand', sans-serif;
    color: $blue;
    text-align: center;
    font-weight: 600;
    font-size: 32px;
  }
}

.form-group {
  position: relative;
}

.form-control {
  font-size: 14px;
  color: $blue;
  height: 50px;
  padding: 0px 30px;
  border: 2px solid #eaeaea;
  outline: none;
  position: relative;
  z-index: 1;
  caret-color: $green;


  &:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    caret-color: $green;
  }

  &::placeholder {
    color: $blue;
    opacity: 0.5;
  }

  &:-moz-focusring {
    color: transparent;
    border: 2px solid $green;
    outline: none;
  }

  &:focus + label {
    background: $green;
    color: white;
    font-size: 70%;
    padding: 1px 6px;
    z-index: 2;
    text-transform: uppercase;
    top: 100%;
    margin-top: -16px;
    height: 14px;
    opacity: 1;
  }


}

label {
  transition: background 0.2s, color 0.2s, top 0.2s, bottom 0.2s, right 0.2s, left 0.2s;
  position: absolute;
  color: #999;
  padding: 7px 6px;
  opacity: 0;
  //top: 0;
  bottom: -30px;
  right: 25px;
  width: calc(100% - 50px);
  border-radius: 25px 25px 0 0;
}

.id_client_box, .id_facture_box, .id_livraison_box {
  padding-left: 20px;
  margin-left: 10px;
  font-style: italic;
  font-size: 14px;
  border-left: 2px solid $blue;
  margin-bottom: 10px;
}

.table_products {
  thead > tr {

    th {
      color: $white;
      border: none;
      background: rgba($green, 0.8);

      &:first-child {
        border-radius: 25px 0 0 25px;

      }

      &:last-child {
        border-radius: 0 25px 25px 0;

      }
    }
  }

  tr {
    border: none;
  }

  td {
    vertical-align: middle;
    border: none;
  }

  tbody tr:nth-of-type(2n+1) {
    background: rgba($green, 0.2)
  }
}


.quantity {
  position: relative;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input {
    width: 80px;
    height: 42px;
    line-height: 1.65;
    float: left;
    display: block;
    padding: 0 0 0 10px;
    margin: 0;
    border: 2px solid $blue;
    border-radius: 10px;
  }

  input:focus {
    outline: 0;
  }

  .quantity-nav {
    float: left;
    position: relative;
    height: 42px;
  }

  .quantity-button {
    position: relative;
    cursor: pointer;
    border-left: 1px solid #eee;
    width: 20px;
    text-align: center;
    color: $blue;
    font-size: 13px;
    font-family: 'Quicksand', sans-serif !important;
    line-height: 1.7;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .quantity-button.quantity-up {
    position: absolute;
    height: 50%;
    top: 0;
    border-bottom: 2px solid $blue;
    border-left: 2px solid $blue;

    font-family: 'Quicksand', sans-serif !important;
    font-size: 20px;
    line-height: 18px;
    font-weight: bold;
    width: 30px;
    text-align: center;

  }

  .quantity-button.quantity-down {
    position: absolute;
    bottom: 0;
    height: 50%;
    border-left: 2px solid $blue;
    border-top: 0;

    font-family: 'Quicksand', sans-serif !important;
    font-size: 20px;
    line-height: 18px;
    font-weight: bold;
    width: 30px;
    text-align: center;
  }
}

.bloc_liste_produits {
  display: inline-block;
  transition: 500ms all ease;
  background: $white;
  border-radius: 25px;
  margin-bottom: 25px;
  border: 1px solid rgba(14, 32, 77, 0.15);

  &:hover {
    box-shadow: 0 5px 83px 0 rgba(14, 32, 77, 0.15);
    border: 1px solid $white;

    .img_liste_produit {
      a {
        opacity: 1;
      }
    }

    & * {
      text-decoration: none;
    }
  }

  .img_liste_produit {
    border-radius: 25px;
    overflow: hidden;
    width: 100%;
    text-align: center;
    position: relative;

    a {
      opacity: 0;
      position: absolute;
      top: 100px;
      transition: 500ms all ease;
      width: 80%;
      left: 10%;
    }

  }

  .titre_liste_produits {
    color: $blue;
    padding: 20px;
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 700;
    width: 100%;
    text-align: center;
    font-size: 18px;
    text-decoration: none;
  }

  .prix_liste_produits {
    text-decoration: none;
    color: $green;
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 900;
    width: 100%;
    padding: 0px 20px 20px 20px;
    text-align: center;
    font-size: 20px;
  }

}

.detail_prod {
  h2 {
    color: $blue;
    padding: 20px 0;
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 700;
    font-size: 30px;
  }

  .img_detail_prod {
    overflow: hidden;
    border-radius: 25px;
    width: 100%;
  }

  .prix_detail_produit {
    text-decoration: none;
    color: $green;
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 900;
    width: 100%;
    padding-bottom: 20px;
    font-size: 20px;
  }

  .details_infos {
    color: rgb(132, 142, 159);

    strong {
      font-weight: 700;
      color: $blue;
    }
  }


}

.cms {
  h1, h2, h3, h4, h5, h6 {
    color: $blue;
    padding: 20px 0;
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 700;
  }

  p {
    color: rgb(132, 142, 159);
    font-size: 14px;
  }

  h2 {
    font-size: 30px;
  }
}